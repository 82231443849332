.valuationBlock {
  flex: 1;
}
.card {
  :global {
    p.paragraph-xs {
      font-size: 14px;
      line-height: 1.6;
      opacity: 1;
    }
  }
}