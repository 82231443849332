@use "scss/functions";
@use "scss/variables";

.dropdown {
  display: inline-block;

  &.chevronAnimation {
    svg {
      transition: transform 0.5s ease;
    }
    &:global {
      &.show {
        svg.chevron {
          transform: rotate(180deg);
        }
      }
    }
  }

  .menu {
    background: #ffffff;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    border: 0;
    width: auto;

    .item {
      padding: 0 functions.get-rem(16px);
      height: 48px;
      line-height: 1.3;
      font-size: functions.get-rem(16px);
      border-radius: 0;
      display: flex;
      align-items: center;
      gap: functions.get-rem(16px);

      &.selected {
        color: variables.$primary-1;
      }

      &.delete {
        color: variables.$critical-500;
        border-top: 1px solid #d8d8d8;

        &.no-border {
          border: none;
        }
      }
      i {
        display: flex;
        font-size: functions.get-rem(24px);
      }
      .label {
        min-width: functions.get-rem(102px);
      }
      &.terminate {
        color: variables.$critical-500;
      }

      &.disabled {
        color: variables.$foreground-disabled !important;

        div {
          color: variables.$foreground-disabled !important;
        }
        i {
          svg {
            path {
              stroke: variables.$foreground-disabled !important;
            }
          }
        }
      }
    }
  }
}
