@use "scss/functions";
@use "scss/variables";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.single-detail {
  width: functions.get-rem(330px);
  height: functions.get-rem(560px);
  padding: functions.get-rem(24px);
  border-radius: variables.$border-radius;
  background-color: variables.$element-1;
  box-shadow: 0 2px 3px #0000000d, 0 4px 8px rgba(0, 0, 0, 0.15);

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  &-empty-img {
    width: functions.get-rem(182px);
    height: functions.get-rem(82px);
    border-radius: variables.$border-radius;
    background-color: variables.$element-1;
  }

  &-tag {
    margin-right: functions.get-rem(12px);
    border-radius: variables.$border-radius;
    background-color: variables.$information-050;
    padding: functions.get-rem(4px) functions.get-rem(8px);

    p {
      text-transform: lowercase;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  &-description {
    margin: 0 auto;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 9;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
  }
}
