@use "scss/variables";
@use "scss/functions";

.wrap {
  margin: 0 auto;
  max-width: 1448px;
}

.shareClassAlign{
  input {
    text-align: right;
  }
}