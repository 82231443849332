@use "scss/functions";
@use "scss/variables";

.programContainer {
  :global {
    .dropdown-divider {
      background-color: #f7f7f7 !important;
      border: 0 !important;
    }
  }
  .table {
    background: variables.$foreground-on-primary;

    td {
      vertical-align: middle !important;
    }

    tfoot {
      tr {
        td {
          padding: 10px;
          background-color: variables.$element-2;
        }
      }
    }
  }

  .empty {
    padding: functions.get-rem(24px) functions.get-rem(32px);
    background: variables.$foreground-on-primary;
    .infoBlock {
      height: functions.get-rem(32px);
      background-color: variables.$foreground-on-primary;
      color: variables.$foreground-medium;
      display: flex;
      align-items: center;
      .link {
        text-decoration: underline;
        color: variables.$primary-1;
        margin: 0 5px;
      }
      .icon {
        font-size: functions.get-rem(24px);
        margin-right: functions.get-rem(8px);
        color: variables.$foreground-low;
        display: flex;
      }
    }
  }
}

.program-header {
  cursor: pointer;
  background-color: variables.$foreground-on-primary;
  padding-right: functions.get-em(16px);

  .rounded-container {
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    background-color: #ececed;
    width: functions.get-rem(20px);
    height: functions.get-rem(20px);
    color: variables.$foreground-low;
    margin-left: functions.get-rem(8px);
  }

  // li elements should be in one line , first point should be hidden
  .subHeader {
    display: flex;
    align-items: center;
    margin: 0;

    padding: 0;
    border-radius: 10px;
    color: variables.$foreground-high;
    font-size: functions.get-rem(12px);
    font-weight: 400;

    li {
      color: variables.$foreground-medium;
      margin-right: 20px;

      &:first-child {
        list-style-type: none;
      }

      &:not(:first-child) {
        margin-left: 8px;
      }
    }

    .approved {
      color: variables.$positive-700;
    }

    .draft {
      color: variables.$warning-900;
    }
  }
}

.sort {
  margin: 0;
  padding: 0;
  height: functions.get-rem(18px);
  display: flex;
  color: #bab6b3;
  font-size: functions.get-rem(12px);
  flex-direction: column;
  justify-content: center;

  &.asc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    transform: rotate(180deg);
  }
  &.active {
    color: variables.$foreground-high;
  }

  &:hover {
    color: variables.$foreground-high;
  }
}

.programTableHead {
  th {
    color: variables.$foreground-medium;
    &:first-child {
      padding-left: 32px;
    }
  }
}

.viewPlan {
  height: functions.get-rem(60px) !important;
}

.programTableRow {
  position: relative;
  transition: all 0.5s;

  td {
    padding: 10px !important;
    &:first-child {
      padding-left: 32px !important;
    }
    &:last-child {
      padding-right: 24px !important;
    }
  }

  &:hover {
    td {
      background-color: variables.$additional-3-500 !important;
    }
    cursor: pointer;
  }

  .representedBy {
    color: variables.$foreground-low;
  }

  .checkmark {
    color: variables.$positive-500;
    font-size: 24px;
  }
  .email {
    color: variables.$foreground-low;
    font-size: 24px;
  }

  .progressBar {
    margin-top: 4px;
    padding: 2px;
    border-radius: 8px;
    width: functions.get-rem(96px) !important;
    height: functions.get-rem(12px) !important;
    margin-left: 0;
    background-color: variables.$element-1 !important;

    div[role="progressbar"] {
      height: 100% !important;
      border-radius: 8px;
      background-color: variables.$positive-500 !important;
      max-width: 100% !important;
    }
  }

  .progressBarNegative {
    @extend .progressBar;

    background-color: variables.$critical-050 !important;
  }

  .expired {
    @extend .progressBar;

    background-color: variables.$surface-1 !important;
    border: 1px dashed variables.$additional-1-550;
  }

  .actionButton {
    font-size: 24px;
    padding: 0 !important;
    width: functions.get-rem(38px);
    height: functions.get-rem(38px);
    color: variables.$foreground-medium;

    svg {
      width: functions.get-rem(24px) !important;
      height: functions.get-rem(24px) !important;
    }
  }
}
.tooltip {
  :global {
    .tooltip-arrow {
      display: none !important;
    }
    .tooltip-inner {
      border: 0;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 4px 16px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      padding: functions.get-rem(12px) functions.get-rem(16px);
    }
  }
}

.program-table {
  &-button {
    color: variables.$foreground-high !important;
    border-color: variables.$foreground-high !important;
  }

  &-empty {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a,
    &-btn {
      cursor: pointer;
      text-decoration: underline;
      color: variables.$foreground-high;
      margin-left: functions.get-rem(8px);

      &:hover {
        color: variables.$foreground-medium;
      }
    }
  }

  &-header {
    padding: 18px 32px 80px 32px;
    background-color: variables.$element-2;

    &-counter {
      border-radius: 50%;
      font-weight: normal;
      width: functions.get-rem(20px);
      height: functions.get-rem(20px);
      color: variables.$foreground-medium;
      background-color: variables.$element-2;
      margin: 0 0 0 functions.get-rem(8px);
    }
  }

  &-button {
    display: flex;
    cursor: pointer;
    position: relative;
    border-radius: 4px;
    align-items: center;
    height: functions.get-rem(46px);
    padding-left: functions.get-rem(24px);
    border: 2px solid variables.$foreground-high;

    &-arrow-container {
      display: flex;
      position: absolute;
      right: functions.get-rem(10px);
    }

    &-content {
      margin-top: -3px;
      border-radius: 4px;
      padding: 4px 0 4px 24px;
      background-color: black;
      color: variables.$foreground-on-primary;

      div {
        cursor: pointer;
      }
    }
  }

  &-selected-container {
    top: 2px;
    right: 0;
    height: 110%;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: flex-end;
    padding-right: 30px !important;
    width: functions.get-rem(220px);
    background: linear-gradient(90deg, transparent, variables.$secondary-1, variables.$secondary-1);

    div > svg {
      transition: all 0.5s;

      &:hover {
        transform: scale(1.3);
      }
    }
  }
}

.action-buttons {
  text-align: right;
}

.one-off-plans {
  .dropdown-divider {
    display: none;
  }

  &-header {
    cursor: pointer;
    height: functions.get-rem(88px);
    padding: 0 functions.get-rem(24px) 0 functions.get-rem(32px);
    background-color: variables.$background-2;
    border: 1px solid variables.$foreground-medium;
  }

  &-content {
    padding: 20px;
    background-color: variables.$background-1;
  }
}

.add-btn {
  padding: 0 !important;
  width: functions.get-rem(32px) !important;
  height: functions.get-rem(32px) !important;

  svg {
    width: functions.get-rem(24px) !important;
    height: functions.get-rem(24px) !important;
  }
}

#footer-progress {
  border-radius: 8px;
  width: functions.get-rem(96px);
  height: functions.get-rem(12px);
  margin-left: 0;
  background-color: variables.$element-1;

  div[role="progressbar"] {
    height: functions.get-rem(8px);
    border-radius: 8px;
    margin-top: 2px;
    margin-left: 2px;
    background-color: variables.$positive-500;
    max-width: 100%;
  }
}
