@use "scss/variables";
@use "scss/functions";

.content {
  margin-top: functions.get-rem(32px);
  padding: functions.get-rem(24px);
  background: variables.$surface-1;
}

.bottomActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


