@use "scss/functions";
@use "scss/variables";

.collapsible {
  display: flex;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
  padding: functions.get-rem(24px) functions.get-rem(32px);
  background-color: variables.$surface-1;

  .icon {
    position: absolute;
    right: functions.get-rem(32px);
  }
}

.head {
  top: 1px;
  z-index: 10;
  position: sticky;
  height: functions.get-rem(40px);

  tr {
    background-color: variables.$surface-1;

    th {
      width: 170px;
      cursor: pointer;
      font-weight: 500;
      vertical-align: middle;
      height: functions.get-rem(40px);

      &:first-child {
        padding-left: functions.get-rem(32px);
      }
    }
  }
}

.sort {
  display: flex;
  margin-left: 4px;
  width: functions.get-rem(24px);
  height: functions.get-rem(18px);

  svg {
    color: variables.$stroke-high;
    width: functions.get-rem(15px) !important;
    height: functions.get-rem(15px) !important;
  }

  &.active {
    svg {
      color: variables.$foreground-high;
    }
  }
}

.body {
  * {
    box-sizing: border-box !important;
  }

  tr {
    position: relative;
    transition: all 0.5s;
    height: functions.get-rem(64px) !important;

    td {
      width: 170px;
      padding-top: 0;
      padding-bottom: 0;
      vertical-align: middle !important;
      height: functions.get-rem(64px) !important;

      div {
        padding-top: 0;
        padding-bottom: 0;
      }

      &.truncate {
        max-width: 1px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:first-child {
        padding-left: functions.get-rem(32px);
      }
    }
  }
}

.footer {
  tr {
    position: relative;

    th {
      border: none !important;
      height: functions.get-rem(64px);
      background-color: variables.$element-2;
    }
  }
}

.table-row {
  height: functions.get-rem(64px);

  &.hovered {
    background-color: variables.$secondary-1 !important;
  }

  &.selected {
    background-color: variables.$information-050;
  }
}

.animated-actions {
  top: 0;
  right: 0;
  width: 40%;
  display: flex;
  position: absolute;
  padding-left: 32px;
  border-top: 2px solid variables.$surface-1;
  border-bottom: 1px solid variables.$surface-1;
  height: clamp(47px, 100%, 100%);
  background: linear-gradient(270deg, variables.$secondary-1 60.29%, rgba(variables.$secondary-1, 0) 80.57%);

  div {
    display: flex;
    margin-left: auto;
    align-items: center;

    svg {
      cursor: pointer !important;
      width: functions.get-rem(24px);
      height: functions.get-rem(24px);
    }
  }
}

.empty-container {
  padding: functions.get-rem(16px);
  background-color: variables.$surface-1;
}

.empty {
  color: variables.$foreground-medium;
  padding: functions.get-rem(16px);
  background-color: variables.$surface-1;
}

.status-tag {
  display: flex;
  border-radius: 8px;
  width: max-content;
  align-items: center;
  justify-content: center;
  height: functions.get-rem(20px);
  padding: 0 functions.get-rem(12px);
  float: right;
}

.actions {
  text-align: center;

  svg {
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.4s;

    &:hover {
      background-color: variables.$surface-1;
    }
  }
}

.actions-toggle {
  border: none;
  padding: 0 !important;
  width: functions.get-rem(40px) !important;
  height: functions.get-rem(40px) !important;
  background-color: variables.$surface-1;

  svg {
    pointer-events: none;
  }

  &:hover {
    border: none;
    background-color: variables.$secondary-1 !important;
  }
}

.add-btn {
  display: flex;
  align-items: center;
  padding: 0 !important;
  justify-content: center;
  color: variables.$primary-1 !important;
  width: functions.get-rem(32px) !important;
  height: functions.get-rem(32px) !important;
  border-color: variables.$foreground-high !important;

  svg {
    width: functions.get-rem(24px) !important;
    height: functions.get-rem(24px) !important;
  }

  &.dark-icon {
    svg {
      color: variables.$foreground-high !important;
    }
  }
}
.representedBy {
  color: variables.$foreground-low;
}

.tooltip {
  :global {
    .tooltip-inner {
      width: functions.get-rem(230px) !important;
      max-width: functions.get-rem(230px) !important;
    }
  }
}
