@use "scss/variables";
@use "scss/bootstrap";

.step-container {
  background-color: variables.$element-1;
  padding: map-get(bootstrap.$spacers, 5);
  max-width: 800px;
}

.disabled {
  filter: grayscale(1);
  * {
    cursor: not-allowed;
  }
}
