@use "scss/functions";
@use "scss/variables";

.social {
  border-radius: 50%;
  transition: all 0.2s;
  width: functions.get-rem(23px);
  height: functions.get-rem(23px);
  background-color: variables.$foreground-high;

  a {
    text-decoration: none;
  }

  &:hover {
    transform: translateX(functions.get-rem(2px));
    background-color: lighten(variables.$foreground-high, 20%);
  }

  &-btn {
    width: functions.get-rem(102px);
    height: functions.get-rem(30px);
    color: variables.$foreground-high !important;
    padding: 0 functions.get-rem(10px) !important;
    background-color: variables.$element-1 !important;
    border: functions.get-rem(2px) solid variables.$foreground-high !important;
  }
}
