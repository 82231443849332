@use "scss/functions";
@use "scss/variables";

.wrap {
  background: variables.$surface-1;
  width: functions.get-rem(876px);
  padding: functions.get-rem(72px) functions.get-rem(64px);
  border-radius: functions.get-rem(8px);
  margin: 0 auto;
}

.card {
  border-radius: 4px;
  background-color: variables.$surface-1;
  border: 1px solid variables.$stroke-medium;

  .badge {
    background-color: variables.$secondary-1 !important;

    div {
      font-size: 12px !important;
    }
  }

  img {
    width: functions.get-rem(115px);
    height: functions.get-rem(115px);
  }

  &:hover {
    background-color: variables.$secondary-1;
    border-color: variables.$secondary-1;

    .badge {
      background-color: variables.$additional-7-200 !important;
    }

    svg {
      transform: translateX(8px);
    }
  }
}
