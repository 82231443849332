@use "scss/functions";
@use "scss/variables";
.wrap {
  background: variables.$surface-1;
  width: functions.get-rem(876px);
  padding: functions.get-rem(64px);
  border-radius: functions.get-rem(8px);
  margin: 0 auto;

  .info {
    display: block;
    max-width: functions.get-rem(876px);
    background: variables.$information-050;
    padding: functions.get-rem(24px);
    border-radius: 4px;
    margin-bottom: functions.get-rem(64px);
  }
}

.share-classes-container {
  border-radius: functions.get-rem(8px);
  background-color: variables.$element-3;
}
