@use "sass:math";
@use "scss/variables";
@use "scss/bootstrap";

.form-check {
  box-sizing: border-box;
  min-height: 40px !important;
  display: flex !important;
  align-items: center;
  //flex: 1;

  &:global {
    &.form-check {
      padding-left: 0;
      position: relative;

      .form-check-input:checked[type="radio"] {
        &:not(:disabled) {
          background-color: variables.$surface-1;
          border-color: variables.$primary-1;

          &:hover {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='pink' stroke='white' stroke-width='1.5px'/></svg>");
          }
        }

        &:disabled {
          background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='gray' stroke='white' stroke-width='1.5px'/></svg>");
        }
      }

      .form-check-input:checked[type="checkbox"] {
        &:hover {
          background-color: transparent;
          border-color: variables.$primary-1;
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='purple' fill-rule='evenodd' clip-rule='evenodd' d='M15.7893 6.10761C16.0405 6.37678 16.0259 6.79864 15.7568 7.04986L8.86317 13.9249C8.60704 14.164 8.20956 14.164 7.95342 13.9249L4.24324 10.1178C3.97407 9.86661 3.95952 9.44475 4.21075 9.17559C4.46197 8.90642 4.88383 8.89187 5.153 9.14309L8.4083 12.5256L14.847 6.07512C15.1162 5.8239 15.538 5.83844 15.7893 6.10761Z'/%3e%3c/svg%3e") !important;
        }
      }

      .form-check-input:disabled {
        border-color: variables.$element-disabled !important;
        background-color: variables.$foreground-disabled !important;
      }

      .form-check-input {
        min-width: 20px;
        cursor: pointer;
        color: inherit;
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;

        &:focus {
          box-shadow: none;
        }

        &:disabled {
          ~ .form-check-label {
            color: variables.$foreground-disabled;
          }
        }
      }

      .form-check-label {
        cursor: pointer;
        display: block;
        text-align: left;
        line-height: bootstrap.$form-check-input-width + bootstrap.$form-check-input-offset * 2;
        padding: bootstrap.$form-check-padding-y bootstrap.$form-check-padding-x bootstrap.$form-check-padding-y 6px;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      }

      &:hover {
        background-color: variables.$element-3 !important;
        border-radius: bootstrap.$border-radius !important;
      }
      &:focus-within {
        // Hidden focus border, need to figure out how to highlight only tab selection
        // outline: 1px solid variables.$primary-1;
        border-radius: bootstrap.$border-radius !important;
      }
    }
  }

  [disabled][type="radio"],
  [disabled][type="checkbox"] {
    &:not(:checked) {
      background-color: variables.$element-disabled !important;
      border: 1px solid variables.$foreground-disabled !important;
    }
  }

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.description {
  margin-top: math.div(map-get(bootstrap.$spacers, 1), 2);
  color: variables.$foreground-medium;

  &.disabled {
    color: variables.$foreground-disabled;
  }
}
