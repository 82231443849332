@use "scss/bootstrap";
@use "scss/functions";
@use "scss/variables";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.team-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: variables.$information-050;
  padding: functions.get-rem(32px) functions.get-rem(44px)
    functions.get-rem(22px) functions.get-rem(44px);

  .accordion {
    display: none;
  }

  @include media-breakpoint-down(xl) {
    border-radius: 4px;
    flex-direction: row;
    align-items: stretch;

    .person,
    .team {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-basis: 50%;
      flex-shrink: 1;
    }

    .team {
      padding-left: functions.get-rem(53px);
      border-left: 1px solid variables.$element-1;
    }

    .person-btn {
      margin: 0 auto;
    }
  }

  @include media-breakpoint-down(md) {
    flex-direction: column;

    .accordion {
      display: block;

      .accordion-btn {
        display: flex;
        justify-content: center;
      }

      .accordion-header {
        button {
          &::after {
            margin-left: 12px;
            transform: rotate(90deg);
          }

          &[aria-expanded="true"] {
            &::after {
              transform: rotate(270deg);
            }
          }
        }
      }
    }

    .team-descktop {
      display: none;
    }

    .team {
      border: none;
      padding: 0;
      margin-top: map-get(bootstrap.$spacers, 3);
    }
  }
}
