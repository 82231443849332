@use "scss/variables";
@use "scss/functions";

@mixin th-td {
  th,
  td {
    @content;
  }
}

.table {
  border-collapse: separate;
  color: variables.$foreground-medium;
  border-spacing: 0 functions.get-rem(1px) !important;

  thead,
  tbody,
  th,
  td {
    border: none !important;
  }

  tr {
    vertical-align: middle;

    &:hover {
      td {
        background-color: variables.$secondary-1 !important;
      }
    }

    &:global {
      &.filtered {
        @include th-td {
          background-color: transparent;
        }
      }

      &.selected {
        @include th-td {
          &:first-child {
            border-bottom-left-radius: 0;
          }

          &:last-child {
            border-bottom-right-radius: 0;
          }
        }
      }
    }

    @include th-td {
      $border-radius: functions.get-rem(variables.$border-radius);

      &:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }

      &:last-child {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }

    th {
      cursor: pointer;
      height: functions.get-rem(40px);
      color: variables.$foreground-medium;
      padding: 0 functions.get-rem(32px) !important;
      border-bottom: 1px solid variables.$stroke-medium !important;
    }

    td {
      padding: functions.get-rem(16px) functions.get-rem(32px);
      cursor: pointer;
    }
  }

  tbody > tr {
    border: none !important;

    &:nth-child(even) {
      td {
        background-color: variables.$additional-1-200;
      }
    }
  }
}
.dilutedColumn {
  width: functions.get-rem(100px);
  color: variables.$primary-1 !important;
}

.blank-item {
  display: flex;
  flex-shrink: 0;
  border-radius: 8px;
  max-width: functions.get-rem(100px);
  height: functions.get-rem(24px);

  &.circle {
    border-radius: 50%;
    width: functions.get-rem(28px);
    height: functions.get-rem(28px);
  }
}

.tr-empty {
  &:hover {
    tr,
    div {
      cursor: initial;
    }

    td {
      cursor: initial;
      background-color: variables.$secondary-1 !important;
    }
  }
}


.tableBorder {
  position: relative;
  border-bottom: 1px solid variables.$element-1;
  font-variant-numeric: tabular-nums !important;

  &.selected {
    td {
      background-color: variables.$additional-7-100 !important;
    }
  }

  &.company {
    td {
      background-color: variables.$information-050 !important;
    }
  }
}

.tableFoot {
  tr {
    position: relative;

    th {
      height: 64px;
      border: none !important;
      background-color: variables.$element-1;
    }
  }
}

.sort {
  display: flex;
  margin-left: 4px;
  width: functions.get-rem(24px);
  height: functions.get-rem(18px);

  svg {
    color: variables.$stroke-high;
    width: functions.get-rem(15px) !important;
    height: functions.get-rem(15px) !important;
  }

  &.active {
    svg {
      color: variables.$foreground-high;
    }
  }
}

#shareholder-details {
  background-color: variables.$additional-7-100 !important;
  padding: functions.get-rem(8px) functions.get-rem(24px) functions.get-rem(32px) functions.get-rem(24px);

  &.company {
    background-color: variables.$information-050 !important;
  }

  &:first-child {
    border-top-left-radius: 0;
  }

  &:last-child {
    border-top-right-radius: 0;
  }

  .container {
    height: auto;
    display: flex;
    position: relative;
    border-radius: 7px;
    padding: functions.get-rem(40px) 0;
    background-color: variables.$foreground-on-primary;

    .section {
      display: flex;
      flex-basis: 33.3%;
      flex-direction: column;
      padding: functions.get-rem(26px) 0;

      &:first-child {
        align-items: center;
      }

      &.bordered {
        border-left: 1px solid variables.$stroke-medium;
        border-right: 1px solid variables.$stroke-medium;
      }

      .tag {
        width: max-content !important;
      }
    }

    .image {
      display: flex;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      width: functions.get-rem(96px);
      height: functions.get-rem(96px);
      font-size: 32px !important;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
}

.avatar {
  padding: 0;
  font-size: 12px !important;
  width: functions.get-rem(24px) !important;
  min-width: functions.get-rem(24px) !important;
  height: functions.get-rem(24px) !important;
}

.close-btn {
  font-size: 24px;
  width: 32px;
  height: 32px;
  position: absolute;
  top: -16px;
  left: -50px;
  &:global {
    &.btn.btn-secondary {
      color: black !important;
    }
  }
}

.pledged-content {
  border-radius: 8px;
  color: variables.$foreground-medium;
  border: 1px solid variables.$foreground-medium;
}

#add-btn {
  width: 40px;
  height: 40px;

  svg {
    margin: 0 !important;
  }

  &:hover {
    background-color: variables.$additional-7-200 !important;
  }

  &:focus,
  &:active {
    background-color: variables.$additional-8-100 !important;
  }
}

.tab-bar-icon {
  width: 24px !important;
  height: 24px !important;
  color: variables.$foreground-high !important;
}

.head-item {
  &.active {
    &:hover {
      background-color: variables.$additional-3-400 !important;
    }
  }
}

.head-row {
  top: 5px;
  z-index: 2;
  position: sticky;
}

.pledge-info {
  :global {
    .tooltip-inner {
      width: functions.get-rem(160px) !important;
      max-width: functions.get-rem(160px) !important;

      p {
        color: variables.$foreground-medium !important;
      }
    }
  }
}

.removeHoverEffects {
  table {
    tr {
      &:hover {
        td {
          background-color: transparent !important;
        }
      }
    }
  }
}
