@use "scss/functions";

$pool-item-font-size: 16px;

.wrap {
  &:global {
    &.accordion-item {
      border: none;
      border-radius: 0 !important;
      background-color: #fff;
      margin-bottom: functions.get-em(24px, $pool-item-font-size);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  :global {
    .accordion-collapse {
      border-radius: 0 !important;
    }
  }
}

;@import "sass-embedded-legacy-load-done:862";