@use "sass:math";
@use "scss/bootstrap";
@use "scss/variables";
@use "scss/functions";

.off-canvas {
  &:global {
    &.offcanvas {
      width: functions.get-rem(627px) !important;

      .offcanvas-header {
        position: relative;

        .btn-close {
          $offset: map-get(bootstrap.$spacers, 5);

          position: absolute;
          top: $offset;
          right: $offset;
          margin: 0;
        }
      }
    }
  }
}

.label {
  font-weight: variables.$font-weight-bold;
  color: variables.$primary-1;
  margin-bottom: functions.get-em(2px);
}
