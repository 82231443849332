@use "scss/variables";

.liquidation-preferences-divider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: variables.$surface-1;

  hr {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 0;
    width: 100%;
  }

  span {
    position: relative;
    z-index: 3;
    display: block;
    color: variables.$foreground-medium;
    background-color: variables.$surface-1;
  }
}
