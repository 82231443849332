@use "scss/variables";
@use "scss/functions";

.actions {
  position: sticky;
  bottom: 10px;
  float: right;
  margin-top: -75px;
  margin-right: 25px;
  display: flex;
  z-index: 99;

  .saveButton {
    min-width: 216px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px 8px 8px;
    border-radius: 48px;
    border: 1px solid variables.$positive-300;
    background: variables.$positive-050;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.05);
    font-size: 16px;
    height: 48px;
    color: variables.$positive-900;
    font-weight: 500;
    margin-left: 16px;

    &.saving {
      border: 1px solid variables.$additional-1-200;
      background: variables.$surface-1;
      color: variables.$foreground-low;
      cursor: not-allowed;
      .leftIcon {
        color: variables.$primary-1;
        font-size: 32px;
      }
    }

    &.hover {
      border: 1px solid variables.$additional-1-200;
      background: variables.$surface-1;
      color: variables.$foreground-low;
      .leftIcon {
        color: variables.$primary-1;
        font-size: 24px;
      }
      .saveNow {
        text-align: left;
        flex: 1;
      }
    }

    .leftIcon {
      font-size: 24px;
      display: flex;
      margin-right: 16px;
    }

    .rightIcon {
      font-size: 24px;
      display: flex;
      margin-left: 12px;
    }
  }
}

.tooltip {
  opacity: 1 !important;

  :global {
    .tooltip-inner {
      padding: 12px 16px !important;
      width: 272px;
      max-width: 272px !important;
      box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.15), 0 2px 8px 0 rgba(0, 0, 0, 0.10);
      border: 1px solid variables.$stroke-high;
      text-align: left;
    }
  }
}