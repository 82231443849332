@use "scss/functions";
@use "scss/variables";

.checkmark {
  font-size: 24px;
  color: variables.$positive-500;
}
.info {
  background: variables.$element-2;
  padding: 1rem;
  .infoIcon {
    font-size: 24px;
    color: variables.$foreground-low;
  }
}
.rightSection {
  border-left: 1px solid variables.$stroke-medium;
}

.closeIcon {
  position: absolute;
  right: 0;

  svg {
    color: variables.$foreground-high !important;
  }
}