@use "scss/variables";
@use "scss/functions";

.summary {
  background-color: transparent !important;
  padding: 0!important;
  h2 {
    font-size: functions.get-rem(36px);
  }
}
.paragraph {
  line-height: 1.6;
  max-width: 488px;
  color: variables.$foreground-high;
}