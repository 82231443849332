@use "scss/variables";
@use "scss/functions";

.valuation {
  &-edit {
    &-content-container {
      padding: 24px;
      position: relative;
      border: 1px solid variables.$surface-1;

      &-message-icon {
        top: 0;
        display: flex;
        position: absolute;
        justify-content: center;
        transform: translateY(-50%);
        left: functions.get-rem(18px);
        width: functions.get-rem(44px);
        background-color: variables.$surface-1;
      }
    }

    &-toast {
      div {
        flex: 1 !important;
      }
    }
  }
}

.tooltip {
  position: absolute;
  top: functions.get-rem(24px);
  right: functions.get-rem(24px);
}

.table-head {
  color: variables.$foreground-medium;
  height: functions.get-rem(48px);
  vertical-align: middle !important;

  tr {
    th {
      padding: 0 functions.get-rem(24px) !important;
    }
  }
}

.table-body {
  tr {
    vertical-align: middle;

    td {
      height: functions.get-rem(56px);
      padding: 0 functions.get-rem(24px);
    }
  }
}

.overtview-chart {
  width: 100%;
  border-radius: 4px;
  height: functions.get-rem(335px);
  background-color: variables.$surface-1;
  border-bottom: 1px solid variables.$surface-1;
  padding: functions.get-rem(24px) functions.get-rem(32px);
}

.overview-details {
  display: flex;
  column-gap: functions.get-rem(24px);
  flex-wrap: wrap;

  .item {
    position: relative;
    border-radius: 4px;
    margin-bottom: 24px;
    width: calc((100% - 48px) / 3);
    color: variables.$foreground-medium;
    background-color: variables.$surface-1;
  }

  .value {
    display: flex;
    align-items: center;
    color: variables.$primary-1;

    div {
      font-size: 20px;
      margin-left: 4px;
      color: variables.$primary-1 !important;
    }
  }
}

.chart-tooltip {
  display: flex;
  border-radius: 4px;
  width: max-content;
  align-items: center;
  transition: all 0.5s;
  padding: 0 16px 0 8px;
  justify-content: center;
  height: functions.get-rem(40px);
  background-color: variables.$surface-1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.15);

  .circle {
    margin-right: 4px;
    border-radius: 50%;
    width: functions.get-rem(12px);
    height: functions.get-rem(12px);
    background-color: variables.$primary-1;
  }

  p {
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 2px 6px rgba(0, 0, 0, 0.15);
  }

  .triangle {
    bottom: -38%;
    position: absolute;
  }
}

.no-shares {
  position: relative;
  background-color: variables.$additional-11-500;

  .close-icon {
    cursor: pointer;
    position: absolute;
    top: functions.get-rem(24px);
    right: functions.get-rem(24px);
    color: variables.$foreground-medium;
    width: functions.get-rem(24px);
    height: functions.get-rem(24px);
  }
}
