@use "../../../../../../scss/variables";
@use "../../../../../../scss/functions";

.service {
  .container {
    gap: 24px;
    background-color: variables.$element-1;

    .card {
      height: 100%;
      flex: 1;
      :global {
        p.paragraph-xs {
          font-size: 14px;
          line-height: 1.3;
        }
      }
    }
  }
}