@use "scss/variables";

.label {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  padding: 4px 8px;
  white-space: nowrap;
  &.primary {
    color: variables.$information-900;
    background: variables.$information-050;
    border-radius: 4px;
  }
  &.warning {
    color: variables.$warning-900;
    background: variables.$warning-050;
    border-radius: 4px;
  }
  &.success {
    color: variables.$positive-900;
    background: variables.$positive-050;
    border-radius: 4px;
  }
  &.danger {
    color: variables.$critical-900;
    background: variables.$critical-050;
    border-radius: 4px;
  }
}
