@use "scss/bootstrap";
@use "scss/functions";
@use "scss/variables";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.company-card-container {
  width: 100%;
  background-color: variables.$information-050;
  padding: functions.get-rem(32px) functions.get-rem(24px);
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: map-get(bootstrap.$spacers, 5);

  @include media-breakpoint-down(xl) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    border-radius: 4px;
  }

  @include media-breakpoint-between(md, xl) {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
      height: 80%;
      background-color: variables.$element-1;
    }
  }

  @include media-breakpoint-down(md) {
    display: flex;
    margin-bottom: 0;
  }

  &-buttons {
    grid-area: 2 / 1 / 3 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @include media-breakpoint-between(xl, xxl) {
      & > button {
        height: fit-content !important;
      }
    }

    @include media-breakpoint-down(xl) {
      width: 200px;
      justify-self: center;
      & > button {
        font-size: functions.get-rem(16px) !important;
      }
    }

    @include media-breakpoint-down(md) {
      order: 1;
      width: 100%;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }

  &-main-info {
    grid-area: 1 / 1 / 2 / 2;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-text {
      text-align: center;
      @include media-breakpoint-down(md) {
        text-align: left;
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: row;
      margin-bottom: 32px;
    }
  }

  &-details {
    grid-area: 1 / 2 / 3 / 3;
    text-align: center;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &-accordion {
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &-circle {
    display: flex;
    position: relative;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    width: functions.get-rem(105px);
    height: functions.get-rem(105px);
    background-color: variables.$element-1;
    margin-bottom: functions.get-rem(24px);

    @include media-breakpoint-down(md) {
      margin-bottom: 0;
      width: functions.get-rem(55px);
      height: functions.get-rem(55px);
      min-width: functions.get-rem(55px);
      margin-right: functions.get-rem(16px);
    }

    &-image {
      position: absolute;
      transform: translate(5px, -5px) scale(0.7);
      @include media-breakpoint-down(md) {
        transform: translate(3px, -3px) scale(0.4);
      }
    }
  }

  &-hr {
    width: 100%;
    height: 1px;
    opacity: 0.2;
    background-color: variables.$foreground-high;

    @include media-breakpoint-down(xl) {
      display: none;
    }
  }
}

.company-card-container-buttons {
  display: flex;
  flex-wrap: wrap;
}
