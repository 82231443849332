@use "scss/variables";

.show-more {
  padding: 8px 21px;
}

.pdf-section {
  transition: 0.2s;
  transform: scaleY(0);
  transform-origin: top;

  &-active {
    transform: scale(1);
  }
}

.optional-text {
  color: #858792;
  font-size: 16px;
  font-weight: normal;
}

.funding-checkbox {
  border-radius: 4px;
  align-items: center;
  display: flex !important;
  transition: all 0.15s ease-in-out;

  & > input {
    margin-top: 0 !important;
  }

  & > label {
    width: 100%;
  }

  &:hover {
    background-color: variables.$primary-1;
  }
}
