@use "scss/variables";
@use "scss/functions";

.headerContainer {
  background-color: variables.$surface-1 !important;
  padding: 0 24px;
}

.headerBorder {
  border: 1px solid variables.$stroke-medium;
}

.container {
  background-color: variables.$surface-1 !important;

  .bodyContainer {
    border-radius: 8px;

    .border {
      border-bottom: 1px solid variables.$stroke-medium;
    }

    &.darkBackground {
      background-color: variables.$element-2 !important;
    }
  }
}

.splitDivider {
  background-color: variables.$additional-1-550;
  border-radius: 32px;
}

.empty {
  border-radius: 4px;
  background-color: variables.$surface-1;
}

.avatar {
  width: functions.get-rem(72px);
  height: functions.get-rem(72px);
}

.top-section {
  flex-basis: 45%;
}

.info-section {
  display: flex;
  flex-basis: 31%;
  border-radius: 4px;
  flex-direction: column;
  justify-content: center;
  height: functions.get-rem(80px);
  border: 1px solid variables.$stroke-medium;

  &.long {
    flex-basis: 65.5%;
  }
}

.documents {
  border-radius: 8px;
  border: 1px solid variables.$stroke-medium;
}

.single-document {
  cursor: pointer;
  flex-basis: 30%;

  &:hover {
    text-decoration: underline;
  }
}
