.team-member {
  &-container {
    padding: 16px 26px 16px 16px;
  }

  &-image {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }

  &-role {
    display: flex;
    padding: 0 8px;
    color: #444444;
    margin-right: 3px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    background-color: #e4e4e4;
    &-last {
      color: black;
      background-color: white;
    }
  }
}
