.card-container {
  width: 247px;
  min-height: 356px;
  border-radius: 4px;
  position: relative;
}

.card-selected {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1), 0 4px 16px rgba(0, 0, 0, 0.15);
}

.card-info-icon {
  top: 10px;
  right: 14px;
  position: absolute;
}

.card-circle {
  width: 130px;
  height: 130px;
  position: relative;
  border-radius: 50%;
}
.card-description {
  opacity: 0.5;
}
