@use "scss/variables";

.team {
  &-member-label {
    opacity: 0.7;
    margin-left: 16px !important;
  }
}

.success-btn {
  background-color: variables.$positive-500 !important;
  border-color: variables.$positive-500 !important;
  transition: all 0.2s;

  &:hover {
    background-color: variables.$positive-500 !important;
    border-color: variables.$positive-500 !important;
  }
}

.error-btn {
  border-color: variables.$critical-500 !important;
  color: variables.$critical-500 !important;

  &:hover {
    border-color: variables.$critical-500 !important;
    color: variables.$critical-500 !important;
    background-color: variables.$element-1 !important;
  }
}
