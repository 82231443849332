.wrap {
  position: relative;
  width: 100%;

  .loaderContent {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    padding-top: 100px;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
}