@use "scss/variables";
@use "scss/functions";

.wrap {
  padding: functions.get-rem(40px);
  max-width: functions.get-rem(968px);
  background-color: variables.$surface-1;
}
.bank-information {
 max-width: functions.get-rem(286px);
}

.address {
  width: 700px;
  .country {
    flex: 1;
  }
}

.person-hint {
  margin: functions.get-em(8px) 0;
  color: variables.$foreground-high;
  opacity: 0.8;
}
