@use "scss/variables";
@use "scss/functions";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.company-list {
  &-logo {
    align-self: center;
    object-fit: contain;
    width: functions.get-rem(176px);
    height: functions.get-rem(126px);
  }

  &-description {
    width: 30%;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  &-list {
    &-wrapper {
      @include media-breakpoint-down(xxl) {
        grid-column-gap: functions.get-rem(16px);
      }
    }

    &-element {
      padding-bottom: 88px;
      flex-basis: 25%;
      @include media-breakpoint-down(xxl) {
        padding-bottom: functions.get-rem(40px);
      }
      @include media-breakpoint-down(md) {
        flex-basis: 100%;
      }
    }
  }

  &-partners {
    display: grid;
    grid-gap: functions.get-rem(62px);
    padding: 0 functions.get-rem(250px);
    grid-template-columns: repeat(5, 1fr);
    justify-content: center;
    @include media-breakpoint-down(xxl) {
      padding: 0;
      grid-gap: functions.get-rem(40px);
      grid-template-columns: repeat(5, max-content);
    }

    @include media-breakpoint-down(lg) {
      grid-gap: functions.get-rem(24px);
      grid-template-columns: repeat(4, max-content);
    }

    @include media-breakpoint-down(sm) {
      grid-gap: functions.get-rem(16px);
      grid-template-columns: repeat(3, max-content);
    }

    &-single {
      width: functions.get-rem(176px);
      height: functions.get-rem(96px);
      @include media-breakpoint-down(xxl) {
        width: functions.get-rem(97px);
        height: functions.get-rem(70px);
      }
      @include media-breakpoint-down(sm) {
        width: functions.get-rem(83px);
        height: functions.get-rem(70px);
      }
    }
  }
}
