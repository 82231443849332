@use "scss/variables";
@use "scss/bootstrap";

.add-share-class-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 330px;
  min-height: 260px;
  border-radius: variables.$border-radius-lg;
  background-color: transparent;
  border: 1px solid variables.$additional-1-550;

  &:hover {
    box-shadow: variables.$dropdown-shadow;
    cursor: pointer;
  }
}

.plus-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  width: 75px;
  border-radius: 50%;
  background-color: variables.$surface-1;
  margin-bottom: map-get(bootstrap.$spacers, 1);
  box-shadow: variables.$dropdown-shadow;
}
