@use "sass:math";

@use "scss/bootstrap";
@use "scss/functions";
@use "scss/variables";
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.cmp-details {
  box-sizing: border-box;
  padding: 0 functions.get-rem(160px);

  @include media-breakpoint-down(xl) {
    padding: 0;
  }

  @include media-breakpoint-down(md) {
    margin: 0 functions.get-rem(20px);
  }

  &-oneliner {
    max-width: functions.get-rem(510px);
  }

  &-video {
    position: relative;
    padding-top: 56.25%;

    &-player {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &-details {
    @include media-breakpoint-down(md) {
      .vertical-line-container {
        margin: 0 40px !important;
      }
    }

    @include media-breakpoint-between(md, xl) {
      .companyPhase,
      .headquarters {
        margin-right: map-get(bootstrap.$spacers, 6);
      }

      .first-subsection {
        display: flex;
        .phase {
          margin-top: 0 !important;
        }
      }
      .vertical-line-container {
        display: none;
      }
    }
  }

  &-img {
    width: auto;
    object-fit: contain;
    object-position: left;
    height: functions.get-rem(105px);
    border-radius: variables.$border-radius;

    @include media-breakpoint-down(xl) {
      height: functions.get-rem(96px);
    }
  }

  &-empty-img {
    width: functions.get-rem(254px);
    height: functions.get-rem(105px);
    border-radius: variables.$border-radius;
    background-color: variables.$element-1;

    @include media-breakpoint-down(xl) {
      height: functions.get-rem(96px);
      width: functions.get-rem(210px);
    }
  }
}

.single-team-member {
  border-radius: 50%;
  background-color: variables.$element-1;

  &-horizontal {
    max-width: calc(100% - 88px);

    &-linked {
      top: 3px;
      right: -20px;
      position: absolute;
    }

    @include media-breakpoint-down(md) {
      width: 250px;
    }

    @media (max-width: 430px) {
      width: 120px;
    }
  }

  &-abs {
    top: 0;
    position: absolute;
    right: functions.get-rem(-14px);
  }

  &-linkedIn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: functions.get-rem(16px);
    height: functions.get-rem(16px);
    border-radius: variables.$border-radius;
    background-color: variables.$foreground-high;
  }

  &-info {
    width: functions.get-rem(104px);
    p:nth-child(1) {
      cursor: default;
      overflow: hidden;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      max-height: functions.get-rem(36px);
    }
  }
}

.vertical-line {
  left: 0;
  width: 1px;
  height: 100%;
  opacity: 0.2;
  position: absolute;
  background-color: variables.$foreground-high;
}

.details-edit-btn {
  padding: functions.get-rem(4px) functions.get-rem(12px) !important;
}
